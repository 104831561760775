<template>
  <div id="mian">
    <div class="header">
      <span @click="toUrl('businessList_shxq', { id: $route.query.id })"
        >基本信息</span
      >
      <span @click="toUrl('addcommercial_jsxx_edit', { id: $route.query.id })"
        >结算信息</span
      >
      <span @click="toUrl('addcommercial_qdxx_edit', { id: $route.query.id })"
        >渠道信息</span
      >
      <span id="fzcolor">产品信息</span>
      <span @click="toUrl('addcommercial_pzxx1', { id: $route.query.id })"
        >配置信息</span
      >

      <span
        v-if="mchDetail.checkStatus == 'PASS'"
        @click="toUrl('addcommercial_dkxq_edit', { id: $route.query.id })"
        >终端信息</span
      >
    </div>
    <div v-auth="'ACCOUNT:MCH:MCHS:PRODUCT'">
      <div class="desc">
        <h5 class="jsxx">支付产品</h5>
      </div>      
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="padding-left: 10px">
              <p>产品类型</p>
            </td>
            <td>
              <p>渠道商户号</p>
            </td>
            <td>
              <p>产品通道</p>
            </td>
            <td>
              <p>结算费率(%)</p>
            </td>
            <td>
              <p>大额费率(%)</p>
            </td>
            <td>
              <p>结算周期</p>
            </td>
            <td>
              <p>状态</p>
            </td>
            <td>
              <p>IP制导</p>
            </td>
            <td>
              <p>贸易模式</p>
            </td>
            <td>
              <p>智能路由</p>
            </td>
          </tr>
          <tr v-for="(v, i) in payProducts" :key="i">
            <td style="padding-left: 10px">
              <p>{{ v.productName }}</p>
            </td>
            <td>
              <p>{{ v.mchNo }}</p>
            </td>
            <td>
              <p>{{ v.payChannelName }}</p>
            </td>
            <td>
              <p>{{ v.settleRate }}</p>
            </td>
            <td>
              <p>{{ v.largeRate }}</p>
            </td>
            <td>
              <p>{{ v.settleCycle }}</p>
            </td>
            <td>
              <p v-show="OFFON_EnabledStatus != 'ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/STATUS'"> {{ v.enabledStatus==true ? "已开启" : "已关闭" }}</p>
              <p v-auth="'ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/STATUS'">
                <el-switch
                  v-model="v.enabledStatus"
                  @change="checkPayStatus(v)"
                  active-color="#13ce66"
                >
                </el-switch>
              </p>
            </td>
            <td>
              <p v-show="OFFON_IpStatus != 'ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/IPZD'"> {{ v.ipStatus==true ? "已开启" : "已关闭" }}</p>
              <p  v-auth="'ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/IPZD'">
                <el-switch
                  v-model="v.ipStatus"
                  @change="setIpStatus(v)"
                  active-color="#13ce66"
                >
                </el-switch>
              </p>
            </td>
            <td>
              <p v-show="OFFON_TradeStatus != 'ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/MYMS'"> {{ v.tradeStatus==true ? "已开启" : "已关闭" }}</p>
              <p v-auth="'ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/MYMS'">
                <el-switch
                  v-model="v.tradeStatus"
                  @change="setTradeStatus(v)"
                  active-color="#13ce66"
                >
                </el-switch>
              </p>
            </td>
            <td>
              <p v-show="OFFON_RouteStatus != 'ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/INTTER'"> {{ v.routeStatus==true ? "已开启" : "已关闭" }}</p>
              <p v-auth="'ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/INTTER'">
                <el-switch
                  v-model="v.routeStatus"
                  @change="setRouteStatus(v)"
                  active-color="#13ce66"
                >
                </el-switch>
              </p>
            </td>
            <!-- <td>
							<p><i  class="lianjie" @click="delPayTab(v)">删除</i></p>
						</td> -->
          </tr>
        </table>
      </div>
      <!-- <h6  class="xzjsxx" @click="addPayTab"><span>+</span> 新增支付产品</h6> -->
      <div class="desc">
        <h5 class="jsxx">代付产品</h5>
      </div> 
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="padding-left: 10px">
              <p>产品类型</p>
            </td>
            <td>
              <p>渠道商户号</p>
            </td>
            <td>
              <p>产品通道</p>
            </td>
            <td>
              <p>费率(%)</p>
            </td>
            <td>
              <p>对公代付(元/笔)</p>
            </td>
            <td>
              <p>对私代付(元/笔)</p>
            </td>
            <td>
              <p>状态</p>
            </td>
            <!-- <td>
							<p>操作</p>
						</td> -->
          </tr>
          <tr v-for="(v, i) in agnpayProducts" :key="i">
            <td style="padding-left: 10px">
              <p>
                {{
                  v.payType == "BANK_PAY_DAILY_ORDER"
                    ? "朝夕付"
                    : v.payType == "BANK_PAY_MAT_ENDOWMENT_ORDER"
                    ? "任意付"
                    : ""
                }}
              </p>
            </td>
            <td>
              <p>{{ v.mchNo }}</p>
            </td>
            <td>
              <p>{{ v.agnpayChannelName }}</p>
            </td>
            <td>
              <p>{{ v.payRate }}</p>
            </td>
            <td>
              <p>{{ v.payCostPublic | formatMoney }}</p>
            </td>
            <td>
              <p>{{ v.payCostPrivate | formatMoney }}</p>
            </td>
            <td>
               <p v-show="OFFON_DFCP_EnabledStatus != 'ACCOUNT:MCH:MCHS:PRODUCT/DFCP/STATUS'"> {{ v.enabledStatus==true ? "已开启" : "已关闭" }}</p>
              <p v-auth="'ACCOUNT:MCH:MCHS:PRODUCT/DFCP/STATUS'">
                <el-switch
                  v-model="v.enabledStatus"
                  @change="setUserPayStatus(v)"
                  active-color="#13ce66"
                >
                </el-switch>
              </p>
            </td>
            <!-- <td>
							<p><i class="lianjie" @click="delAgnPayTab(v)">删除</i></p>
						</td> -->
          </tr>
        </table>
      </div>
      <!-- <h6  class="xzjsxx" @click="addAgnPayTab"><span>+</span> 新增代付产品</h6> -->
      <div class="desc">
        <h5 class="jsxx">分账产品</h5>
      </div> 
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="padding-left: 10px">
              <p>产品类型</p>
            </td>
            <td>
              <p>渠道商户号</p>
            </td>
            <td>
              <p>产品通道</p>
            </td>
            <td>
              <p>结算费率(%)</p>
            </td>
            <td>
              <p>结算成本(元/笔)</p>
            </td>
            <td>
              <p>状态</p>
            </td>
            <!-- <td>
							<p>操作</p>
						</td> -->
          </tr>
          <tr v-for="(v, i) in allocProducts" :key="i">
            <td style="padding-left: 10px">
              <p>
                {{
                  v.settleType == "AUTO"
                    ? "自动结算"
                    : v.settleType == "MANUAL"
                    ? "手工结算"
                    : v.settleType == "MANUAL_INSTANT"
                    ? "手工即时结算"
                    : ""
                }}
              </p>
            </td>
            <td>
              <p>{{ v.mchNo }}</p>
            </td>
            <td>
              <p>{{ v.allocChannelName }}</p>
            </td>
            <td>
              <p>{{ v.settleRate }}</p>
            </td>
            <td>
              <p>{{ v.settleCost | formatMoney }}</p>
            </td>
            <td>
              <p v-show="OFFON_FZCP_EnabledStatus != 'ACCOUNT:MCH:MCHS:PRODUCT/FZCP/STATUS'"> {{ v.enabledStatus==true ? "已开启" : "已关闭" }}</p>
              <p v-auth="'ACCOUNT:MCH:MCHS:PRODUCT/FZCP/STATUS'">
                <el-switch
                  v-model="v.enabledStatus"
                  @change="setSplitPayStatus(v)"
                  active-color="#13ce66"
                >
                </el-switch>
              </p>
            </td>
            <!-- <td>
							<p><i class="lianjie" @click="delAllTab(v)">删除</i></p>
						</td> -->
          </tr>
        </table>
      </div>
      <!-- <h6  class="xzjsxx" @click="addAllTab"><span>+</span> 新增分账产品</h6> -->
      <!-- <div class="btn">
				<el-button @click="toUrl('addcommercial_qdxx_edit',{id:$route.query.id})" class="back">上一步</el-button>
				<el-button @click="toUrl('addcommercial_pzxx1',{id:$route.query.id})" class="next" type="primary">下一步</el-button>
			</div> -->
      <el-dialog title="新增支付产品" :visible.sync="userPayShow" width="490px">
        <ul class="overAccount_ul">
          <li class="ul_left">
            <div class="overAccount_ul_title"><i>*</i>关联渠道</div>
            <el-select
              v-model="mchChannelName"
              placeholder="选择关联渠道"
              @change="mchChannelNameChange"
            >
              <el-option
                v-for="(v, i) in formData"
                :key="i"
                :label="v.mchNo"
                :value="v.mchChannelId"
              >
              </el-option>
            </el-select>
          </li>
          <li class="ul-right">
            <div class="overAccount_ul_title"><i>*</i>支付类型</div>
            <el-select
              v-model="payFormData.productId"
              placeholder="选择支付类型"
              @change="payDropChange"
              :disabled="!mchChannelName && mchChannelName !== 0"
            >
              <el-option
                v-for="(v, i) in payDropLists"
                :key="i"
                :label="v.productName"
                :value="v.productId"
                :disabled="v.disabled"
              >
              </el-option>
            </el-select>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="userPayShow = false"
            >取 消</el-button
          >
          <el-button class="addBt" @click="sureAddPayTab">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="新增代付产品" :visible.sync="hasPayShow" width="490px">
        <ul class="overAccount_ul">
          <li class="ul_left">
            <div class="overAccount_ul_title"><i>*</i>关联渠道</div>
            <el-select
              v-model="mchChannelName"
              placeholder="选择关联渠道"
              @change="mchChannelNameChange1"
            >
              <el-option
                v-for="(v, i) in formData"
                :key="i"
                :label="v.mchNo"
                :value="v.mchChannelId"
              >
              </el-option>
            </el-select>
          </li>
          <li class="ul-right">
            <div class="overAccount_ul_title"><i>*</i>代付类型</div>
            <el-select
              v-model="agnPayFormData.payType"
              placeholder="选择代付类型"
              @change="agnPayDropChange"
              :disabled="!mchChannelName && mchChannelName !== 0"
            >
              <el-option
                v-for="(v, i) in AgnpayDropOption"
                :key="i"
                :label="v.title"
                :value="v.value"
                :disabled="v.disabled"
              >
              </el-option>
            </el-select>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="hasPayShow = false"
            >取 消</el-button
          >
          <el-button class="addBt" @click="sureAddAgnPayTab">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="新增分账产品" :visible.sync="accountShow" width="490px">
        <ul class="overAccount_ul">
          <li class="ul_left">
            <div class="overAccount_ul_title"><i>*</i>关联渠道</div>
            <el-select
              v-model="mchChannelName"
              placeholder="选择关联渠道"
              @change="mchChannelNameChange2"
            >
              <el-option
                v-for="(v, i) in formData"
                :key="i"
                :label="v.mchNo"
                :value="v.mchChannelId"
              >
              </el-option>
            </el-select>
          </li>
          <li class="ul-right">
            <div class="overAccount_ul_title"><i>*</i>分账结算类型</div>
            <el-select
              v-model="allFormData.settleType"
              placeholder="选择分账结算类型"
              @change="allDropChange"
              :disabled="!mchChannelName && mchChannelName !== 0"
            >
              <el-option
                v-for="(v, i) in allDropOption"
                :key="i"
                :label="v.title"
                :value="v.value"
                :disabled="v.disabled"
              >
              </el-option>
            </el-select>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="exitBt" @click="accountShow = false"
            >取 消</el-button
          >
          <el-button class="addBt" @click="sureAddAllTab">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  payDrop,
  agnpayDrop,
  allDrop,
  addShop,
  getProducts,
  shopDetail,
  setPay,
  delPay,
  setPayStatus,
  setUserPay,
  delUserPay,
  setUserPayStatus,
  setSplitPay,
  delSplitPay,
  setSplitPayStatus,
  mchchannelsDrop,
  setIpStatus,
  setRouteStatusUrl,
  setProductTradeStatus,
  getConfig,
} from "@/api/user/shopManager.js";
export default {
  data() {
    return {
      userPayShow: false, //新增支付展示
      hasPayShow: false, //新增代付展示
      accountShow: false, //新增分账展示
      payProducts: [], //支付产品列表
      agnpayProducts: [], //代付产品列表
      allocProducts: [], //分账产品列表
      formData: [], //渠道信息
      payFormData: {
        //支付产品
        productId: "", //true	支付产品ID
        payChannelId: "", //true	支付产品通道ID
        refundStrategy: "", //true	退款策略
        settleCycle: "", //true	结算周期，
        settleRate: "", //true	结算费率%
        largeRate: "", //false	大额费率%
        dayLimit: "", //true	单日限额，单位分
        singleMinimumLimit: "", //true	单笔最小限额，单位分
        singleMaximumLimit: "", //true	单笔最大限额，单位分
        riskThreshold: "", //true	风控阈值（0-100）
        riskStatus: "", //true	风控状态
      },
      payDropLists: [], //支付产品列表
      payDropListsChild: [], //付产品通道列表
      editPayIndex: null,
      editPayIndexChild: null,
      agnPayFormData: {
        //代付产品
        payType: "", //true	代付产品类型
        agnpayChannelId: "", //true	代付产品通道ID
        payRate: "", //true	代付费率%
        payCostPublic: "", //true	对公代付（单位分/笔）
        payCostPrivate: "", //true	对私代付（单位分/笔）
        dayLimit: "", //true	单日限额，单位分
        singleLimit: "", //true	单笔限额，单位分
      },
      AgnpayDropLists: [], //代付产品类型列表
      AgnpayDropOption: [
        {
          title: "朝夕付",
          value: "BANK_PAY_DAILY_ORDER",
          disabled: false,
        },
        {
          title: "任意付",
          value: "BANK_PAY_MAT_ENDOWMENT_ORDER",
          disabled: false,
        },
      ],
      AgnpayDropListsChild: [], //代付产品通道列表
      editAgnPayIndex: null,
      editAgnPayIndexChild: null,
      allFormData: {
        //分账展示
        settleType: "", //true	结算方式
        allocChannelId: "", //true	分账产品通道ID
        settleRate: "", //true	结算费率%
        settleCost: "", //true	结算成本（单位分/笔）
      },
      allDropLists: [], //结算方式列表
      allDropOption: [
        {
          title: "自动结算",
          value: "AUTO",
          disabled: false,
        },
        {
          title: "手工结算",
          value: "MANUAL",
          disabled: false,
        },
        {
          title: "手工即时结算",
          value: "MANUAL_INSTANT",
          disabled: false,
        },
      ],
      allDropListsChild: [], //分账产品通道列表
      editAllIndex: null,
      editAllIndexChild: null,
      mchChannelName: "", //新增渠道
      // tradeStatus: "", //贸易模式状态 0为关闭状态 1为开启状态
      mchDetail: "", //基本信息
      
      OFFON_EnabledStatus:"ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/STATUS",
      OFFON_IpStatus:"ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/IPZD",
      OFFON_TradeStatus:"ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/MYMS",
      OFFON_DFCP_EnabledStatus:"ACCOUNT:MCH:MCHS:PRODUCT/DFCP/STATUS",
      OFFON_FZCP_EnabledStatus:"ACCOUNT:MCH:MCHS:PRODUCT/FZCP/STATUS",
      OFFON_RouteStatus:'ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/INTTER',
    };
  },
  created() {
    this.getList();
    this.getDetail();
    // this._getTradeStatus(); //获取贸易模式状态
    this.listJurisdiction();
  },
  methods: {
    //获取产品列表信息
    getList() {
      getProducts(this.$route.query.id).then((res) => {
        if (res.resultStatus) {
          this.payProducts = res.resultData.payProducts;
          this.agnpayProducts = res.resultData.agnpayProducts;
          this.allocProducts = res.resultData.allocProducts;
          this.payProducts.forEach((val) => {
            val.dayLimit /= 100;
            val.singleMinimumLimit /= 100;
            val.singleMaximumLimit /= 100;
          });
          this.agnpayProducts.forEach((val) => {
            val.payCostPublic /= 100;
            val.payCostPrivate /= 100;
            val.dayLimit /= 100;
            val.singleLimit /= 100;
          });
          this.allocProducts.forEach((val) => {
            val.settleCost /= 100;
          });
        }
      });
    },
    //获取渠道信息
    getDetail() {
      mchchannelsDrop(this.$route.query.id).then((res) => {
        if (res.resultStatus) {
          this.formData = res.resultData;
          // 添加orgId
          shopDetail(this.$route.query.id).then((res) => {
            if (res) {
              this.mchDetail = res.resultData.mchDetail;
              console.log("基本信息", this.mchDetail);
              this.formData.forEach((v) => {
                res.resultData.mchChannels.forEach((val) => {
                  if (v.mchChannelId == val.mchChannelId) {
                    v.orgId = val.orgId;
                  }
                });
              });
            }
          });
        }
      });
    },
    // 获取支付下拉选择
    async getPayDrop(value) {
      let data = {
        orgId: value,
        mchChannelId: "",
      };
      await payDrop(data).then((res) => {
        this.payDropLists = res.resultData;
        this.payDropLists.forEach((v) => {
          v.disabled = false;
        });
        this.payProducts.forEach((v) => {
          this.payDropLists.forEach((val) => {
            if (
              v.mchChannelId == this.mchChannelName &&
              v.productId == val.productId
            ) {
              val.disabled = true;
            }
          });
        });
      });
    },
    // 支付渠道选择
    mchChannelNameChange(id) {
      this.formData.forEach((v) => {
        if (v.mchChannelId == id) {
          this.getPayDrop(v.orgId);
        }
      });
    },
    // 支付下拉选择
    payDropChange(value) {
      this.payFormData.payChannelId = "";
      this.payDropListsChild = [];
      this.payDropLists.forEach((v) => {
        if (v.productId === value) {
          this.payDropListsChild = v.payChannels;
        }
      });
    },
    // 新增支付
    addPayTab() {
      (this.payFormData = {
        productId: "",
        payChannelId: "",
        refundStrategy: "WITH_SERVICE_CHARGE",
        settleCycle: "T+1",
        settleRate: 0,
        largeRate: 0,
        dayLimit: 0,
        singleMinimumLimit: 0,
        singleMaximumLimit: 0,
        riskThreshold: 0,
        riskStatus: 0,
      }),
        (this.userPayShow = true);
      this.editPayIndex = null;
      this.mchChannelName = "";
    },
    // 新增支付确认
    sureAddPayTab() {
      let data = JSON.parse(JSON.stringify(this.payFormData));
      if (!data.productId) {
        this.$message.error("支付类型必选");
        return false;
      }
      this.payDropLists.forEach((v) => {
        data.payChannelId = v.payChannels[0].payChannelId;
      });
      setPay(this.$route.query.id, this.mchChannelName, data).then((res) => {
        if (res) {
          this.$message.success("保存成功");
          this.getList();
          this.userPayShow = false;
        }
      });
    },
    // 修改支付状态
    checkPayStatus(value) {
      this.$confirm("此操作将修改状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let mchId = this.$route.query.id;
          let mchChannelId = value.mchChannelId;
          let data = {
            mchId: this.$route.query.id,
            productId: value.productId,
            mchChannelId: value.mchChannelId,
            enabledStatus: value.enabledStatus,
          };

          console.log("切换状态", setPayStatus);
          setPayStatus(mchId, mchChannelId, data).then((res) => {
            if (!res.resultStatus) {
              value.enabledStatus = value.enabledStatus ? false : true;
            }
          });
        })
        .catch(() => {
          value.enabledStatus = value.enabledStatus ? false : true;
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    //获取贸易模式状态 贸易模式关闭的时候才能开启IP制导
    // _getTradeStatus() {
    //   getConfig(this.$route.query.id).then((res) => {
    //     if (res.resultStatus) {
    //       this.tradeStatus = res.resultData.authConfig.tradeStatus;
    //     }
    //     console.log("贸易模式状态", this.tradeStatus);
    //   });
    // },

    // 修改支付状态IP制导
    setIpStatus(value) {
      this.$confirm("此操作将修改IP制导状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (!value.routeStatus && !value.tradeStatus) {
            let data = {
              mchId: this.$route.query.id,
              productId: value.productId,
              mchChannelId: value.mchChannelId,
              ipStatus: value.ipStatus,
            };
            //判断IP制导是要开启还是关闭
            if (value.ipStatus) {
              setIpStatus(data).then((res) => {
                  if (!res.resultStatus) {
                    value.ipStatus = value.ipStatus ? false : true;
                    this.$message.error("IP制导开启失败");
                  } else {
                    this.$message.success("IP制导开启成功");
                  }
              });
            } else {
              setIpStatus(data).then((res) => {
                if (!res.resultStatus) {
                  value.ipStatus = value.ipStatus ? false : true;
                  this.$message.error("IP制导关闭失败");
                } else {
                  this.$message.success("IP制导关闭成功");
                }
              });
            }
          } else {
            this.$message.error("IP制导/贸易模式/智能路由只能同时开启一种,请先关闭配置信息贸易模式或智能路由");
            value.ipStatus = false;
          }
        })
        .catch(() => {
          value.ipStatus = value.ipStatus ? false : true;
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    //修改支付状态贸易模式
    setTradeStatus(value) {
      this.$confirm("此操作将修改贸易模式状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            mchId: this.$route.query.id,
            productId: value.productId,
            mchChannelId: value.mchChannelId,
            tradeStatus: value.tradeStatus,
          };

          if (!value.routeStatus && !value.ipStatus) {
            //判断贸易模式开关是要开启还是关闭
            if (value.tradeStatus) {
              // 打开贸易开关时,IP制导状态为关闭状态才能打开贸易模式
              setProductTradeStatus(data).then((res) => {
                  if (!res.resultStatus) {
                    value.tradeStatus = value.tradeStatus ? false : true;
                    this.$message.error("贸易模式开启失败");
                  } else {
                    this.$message.success("贸易模式开启成功");
                  }
              });
            } else {
              setProductTradeStatus(data).then((res) => {
                if (!res.resultStatus) {
                  value.tradeStatus = value.tradeStatus ? false : true;
                  this.$message.error("贸易模式关闭失败");
                } else {
                  this.$message.success("贸易模式关闭成功");
                }
              });
            }
          } else {
            this.$message.error("IP制导/贸易模式/智能路由只能同时开启一种,请先关闭配置信息IP制导或智能路由");
            value.tradeStatus = false;
          }
        })
        .catch(() => {
          value.tradeStatus = value.tradeStatus ? false : true;
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    //修改智能路由状态
    setRouteStatus(value){
      this.$confirm("此操作将修改智能路由状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // IP制导/贸易模式/智能路由只能同时开启一种 
          if (!value.tradeStatus && !value.ipStatus) {
            let data = {
              mchId: this.$route.query.id,
              productId: value.productId,
              mchChannelId: value.mchChannelId,
              routeStatus: value.routeStatus,
            };
            //判断IP制导是要开启还是关闭
            if (value.routeStatus) {
              setRouteStatusUrl(data).then((res) => {
                  if (!res.resultStatus) {
                    value.routeStatus = value.routeStatus ? false : true;
                    this.$message.error("智能路由开启失败");
                  } else {
                    this.$message.success("智能路由开启成功");
                  }
              });
            } else {
              // console.log("现在要关闭IP制导");
              setRouteStatusUrl(data).then((res) => {
                if (!res.resultStatus) {
                  value.routeStatus = value.routeStatus ? false : true;
                  this.$message.error("智能路由关闭失败");
                } else {
                  this.$message.success("智能路由关闭成功");
                }
              });
            }
          } else {
            this.$message.error("IP制导/贸易模式/智能路由只能同时开启一种,请先关闭配置信息IP制导或贸易模式");
            value.routeStatus = false;
          }
        })
        .catch(() => {
          value.routeStatus = value.routeStatus ? false : true;
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 删除支付
    delPayTab(value) {
      this.$confirm("此操作将删除该支付产品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delPay(
            this.$route.query.id,
            value.mchChannelId,
            value.productId
          ).then((res) => {
            if (res) {
              this.getList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 获取代付下拉选择
    async getAgnpayDrop(id) {
      let data = {
        orgId: id,
        mchChannelId: "",
      };
      await agnpayDrop(data).then((res) => {
        this.AgnpayDropLists = res.resultData;
        this.AgnpayDropOption.forEach((val) => {
          val.disabled = false;
        });
        this.agnpayProducts.forEach((v) => {
          this.AgnpayDropOption.forEach((val) => {
            if (
              v.mchChannelId == this.mchChannelName &&
              v.payType == val.value
            ) {
              val.disabled = true;
            }
          });
        });
      });
    },
    mchChannelNameChange1(id) {
      this.formData.forEach((v) => {
        if (v.mchChannelId == id) {
          this.getAgnpayDrop(v.orgId);
        }
      });
    },
    // 代付下拉选择
    agnPayDropChange(value) {
      this.agnPayFormData.agnpayChannelId = "";
      this.AgnpayDropListsChild = [];
      this.AgnpayDropLists.forEach((v) => {
        if (v.payType === value) {
          this.AgnpayDropListsChild = v.agnpayChannels;
        }
      });
    },
    // 新增代付
    addAgnPayTab() {
      (this.agnPayFormData = {
        payType: "",
        agnpayChannelId: "",
        payRate: 0,
        payCostPublic: 0,
        payCostPrivate: 0,
        dayLimit: 0,
        singleLimit: 0,
      }),
        (this.hasPayShow = true);
      this.editAgnPayIndex = null;
      this.mchChannelName = "";
    },
    // 新增代付确认
    sureAddAgnPayTab() {
      let data = JSON.parse(JSON.stringify(this.agnPayFormData));
      if (!data.payType) {
        this.$message.error("代付类型必选");
        return false;
      }
      data.agnpayChannelId = this.AgnpayDropListsChild[0]
        ? this.AgnpayDropListsChild[0].agnpayChannelId
        : "";
      setUserPay(this.$route.query.id, this.mchChannelName, data).then(
        (res) => {
          if (res) {
            this.$message.success("保存成功");
            this.getList();
            this.hasPayShow = false;
          }
        }
      );
    },
    // 修改代付状态
    setUserPayStatus(value) {
      this.$confirm("此操作将修改状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            mchId: this.$route.query.id,
            payType: value.payType,
            mchChannelId: value.mchChannelId,
            enabledStatus: value.enabledStatus,
          };
          setUserPayStatus(data).then((res) => {
            if (!res) {
              value.enabledStatus = value.enabledStatus ? false : true;
            }
          });
        })
        .catch(() => {
          value.enabledStatus = value.enabledStatus ? false : true;
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 删除代付
    delAgnPayTab(value) {
      this.$confirm("此操作将删除该代付产品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delUserPay(
            this.$route.query.id,
            value.mchChannelId,
            value.payType
          ).then((res) => {
            if (res) {
              this.getList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 获取分账下拉选择
    async getAllDrop(id) {
      let data = {
        orgId: id,
        mchChannelId: "",
      };
      await allDrop(data).then((res) => {
        this.allDropLists = res.resultData;
        this.allDropOption.forEach((val) => {
          val.disabled = false;
        });
        this.allocProducts.forEach((v) => {
          this.allDropOption.forEach((val) => {
            if (
              v.mchChannelId == this.mchChannelName &&
              v.settleType == val.value
            ) {
              val.disabled = true;
            }
          });
        });
      });
    },
    mchChannelNameChange2(id) {
      this.formData.forEach((v) => {
        if (v.mchChannelId == id) {
          this.getAllDrop(v.orgId);
        }
      });
    },
    // 分账下拉选择
    allDropChange(value) {
      this.allFormData.allocChannelId = "";
      this.allDropListsChild = [];
      this.allDropLists.forEach((v) => {
        if (v.settleType === value) {
          this.allDropListsChild = v.allocChannels;
        }
      });
    },
    // 新增分账
    addAllTab() {
      (this.allFormData = {
        settleType: "",
        allocChannelId: "",
        settleRate: 0,
        settleCost: 0.01,
      }),
        (this.accountShow = true);
      this.editAllIndex = null;
      this.mchChannelName = "";
    },
    // 新增分账确认
    sureAddAllTab() {
      let data = JSON.parse(JSON.stringify(this.allFormData));
      if (!data.settleType) {
        this.$message.error("产品类型必选");
        return false;
      }
      data.allocChannelId = this.allDropListsChild[0]
        ? this.allDropListsChild[0].allocChannelId
        : "";
      setSplitPay(this.$route.query.id, this.mchChannelName, data).then(
        (res) => {
          if (res) {
            this.$message.success("保存成功");
            this.getList();
            this.accountShow = false;
          }
        }
      );
    },
    // 修改分账状态
    setSplitPayStatus(value) {
      this.$confirm("此操作将修改状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            mchId: this.$route.query.id,
            settleType: value.settleType,
            mchChannelId: value.mchChannelId,
            enabledStatus: value.enabledStatus,
          };
          setSplitPayStatus(data).then((res) => {
            if (!res) {
              value.enabledStatus = value.enabledStatus ? false : true;
            }
          });
        })
        .catch(() => {
          value.enabledStatus = value.enabledStatus ? false : true;
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 删除分账
    delAllTab(value) {
      this.$confirm("此操作将删除该分账产品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delSplitPay(
            this.$route.query.id,
            value.mchChannelId,
            value.settleType
          ).then((res) => {
            if (res) {
              this.getList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 跳转页面
    toUrl(name, query) {
      this.$router.push({
        name: name,
        query: {
          ...query,
        },
      });
    },
    submit() {
      let formData = [];
      this.formData.forEach((v, i) => {
        let data = {
          mchChannelDetail: v,
          payProducts: this.payProducts[i],
          agnpayProducts: this.agnpayProducts[i],
          allocProducts: this.allocProducts[i],
        };
        formData.push(data);
      });
      sessionStorage.setItem("mchChannels", JSON.stringify(formData));
      let data = {
        mchDetail: JSON.parse(sessionStorage.getItem("mchDetail")),
        settlements: JSON.parse(sessionStorage.getItem("settlements")),
        mchChannels: JSON.parse(sessionStorage.getItem("mchChannels")),
      };
      addShop(data).then((res) => {
        if (res) {
          this.$router.push({
            name: "businessList_shlb",
          });
        }
      });
    },
    //判断列表按钮是否有权限
    listJurisdiction(){
        const _resources = JSON.parse(sessionStorage.getItem('userInfo')).resources;
         if(_resources=='ALL'){
              this.OFFON_EnabledStatus = "ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/STATUS";
              this.OFFON_IpStatus = "ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/IPZD";
              this.OFFON_TradeStatus = "ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/MYMS";
              this.OFFON_DFCP_EnabledStatus = "ACCOUNT:MCH:MCHS:PRODUCT/DFCP/STATUS";
              this.OFFON_FZCP_EnabledStatus = "ACCOUNT:MCH:MCHS:PRODUCT/FZCP/STATUS";
              this.OFFON_RouteStatus = "ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/INTTER";
         }else{
            if(_resources.split(';').indexOf('ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/STATUS') !== -1 ){
              this.OFFON_EnabledStatus = "ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/STATUS";
            }else{
              this.OFFON_EnabledStatus = "";
            }
            if(_resources.split(';').indexOf('ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/IPZD') !== -1 ){
              this.OFFON_IpStatus = "ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/IPZD";
            }else{
              this.OFFON_IpStatus = "";
            }
            if(_resources.split(';').indexOf('ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/MYMS') !== -1 ){
              this.OFFON_TradeStatus = "ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/MYMS";
            }else{
              this.OFFON_TradeStatus = "";
            }
            if(_resources.split(';').indexOf('ACCOUNT:MCH:MCHS:PRODUCT/DFCP/STATUS') !== -1 ){
              this.OFFON_DFCP_EnabledStatus = "ACCOUNT:MCH:MCHS:PRODUCT/DFCP/STATUS";
            }else{
              this.OFFON_DFCP_EnabledStatus = "";
            }
            if(_resources.split(';').indexOf('ACCOUNT:MCH:MCHS:PRODUCT/FZCP/STATUS') !== -1 ){
              this.OFFON_FZCP_EnabledStatus = "ACCOUNT:MCH:MCHS:PRODUCT/FZCP/STATUS"
            }else{
              this.OFFON_FZCP_EnabledStatus = "";
            }
            if(_resources.split(';').indexOf('ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/INTTER') !== -1 ){
              this.OFFON_RouteStatus = "ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/INTTER"
            }else{
              this.OFFON_RouteStatus = "";
            }
         }

    },
  },
};
</script>
<style scoped>
@import "../../../assets/css/desc.css";
.tab1{
  min-height: auto !important;
}
.next {
  width: 120px;
  height: 32px;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  padding: 0;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #dce0e6;
  border-radius: 3px;
  padding: 0;
  font-size: 12px;
  color: #333333;
}

.btn {
  width: 100%;
  text-align: center;
  height: 33px;
  margin-top: 50px;
  margin-bottom: 18px;
}

.title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 40px;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
  text-align: left;
}

.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul .ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.overAccount_ul .ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  border-color: #5aafab;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  border-color: #dce0e6;
  color: #333333;
  font-size: 12px;
  padding: 0;
}
</style>
